<script lang="ts">
export default {
  name: "DefaultLayout",
};
</script>
<script setup lang="ts">
// Navigation for default theme
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 2 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 2 });
});
provide("swNavigation-footer-navigation", footerData);
</script>
<template>
  <div class="min-w-full min-h-screen flex flex-col">
    <LayoutHeader class="w-full" />
    <LayoutNotifications />
    <main class="mx-auto w-full max-w-superlarge grow">
      <slot />
    </main>
    <LayoutFooter class="w-full max-w-superlarge mx-auto" />
  </div>
</template>
